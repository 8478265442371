const Loading = ({ loading = false, children }) => {
  return (
    <div className="tab-content">
      {loading ? (
        <div className="tab-loading" style={{ height: 624, display: "block" }}>
          <div>
            <h5 className="display-4">
              Tab is loading <i className="fa fa-sync fa-spin" />
            </h5>
          </div>
        </div>
      ) : (
        <div
          className="tab-pane fade active show"
          id="panel-pages-UI-buttons-html"
          role="tabpanel"
          aria-labelledby="tab-pages-UI-buttons-html"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Loading;
