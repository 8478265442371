import { Link } from "react-router-dom";
import React, { useEffect,useRef, useState } from "react";
import { request } from "../../../../util/request";
import Loadings from "..//../Loading";
import Pagination  from "..//../Pagination";
import DataTableInfo  from "..//../DataTableInfo";
import Swal from "sweetalert2";
const ListRoles = () => {
  const [ListRole, setListRole] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const filter = useRef({
    filterName: "",
    filterpage: 1,
    filterlimit: 10,
    totalpage: "",
  });
  useEffect(() => {
    getRoles();
  }, []);
  
  const getRoles = async () => {
    setLoading(true);
    var param = {
      filterName: filter.current.filterName,
      filterpage: filter.current.filterpage,
      filterlimit: filter.current.filterlimit,
    };
    
    const response = await request("role/getlist", "get", param);
    setLoading(false);
    if (response) {
      setListRole(response.data.data);
      if (filter.current.filterpage === 1) {
        setTotalRecords(response.data.totalRecords);
        const pages = Math.ceil(response.data.totalRecords / filter.current.filterlimit);
        filter.current.totalpage = pages;
      }
    }
  };
  const onChangeSearchName = (event) => {
    filter.current.filterName = event.target.value;
    getRoles();
  };
  const handleLimit = (event) => {
    filter.current.filterlimit = event.target.value;
    getRoles();
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= filter.current.totalpage) {
      filter.current.filterpage = pageNumber;
      getRoles();
    }
  };
  const DeleteItem = (itemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await request("role/delete/" + itemId, "delete");
        if (response.data.status) {
          Swal.fire("Deleted!", "Your item has been deleted.", "success");
          getRoles();
        }else if (response.data.error) {
          if (response.data.error.Id) {
            Swal.fire({
              title: "Please Check Information Role",
              text: response.data.error.Id,
              icon: "error"
            });
          }
        }
      }
    });
  };
  return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">List Roles</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                    Please use the table below to navigate or filter the results. You can download the table as excel and pdf.
                    </h5>
                  </div>
                  <div className="card-body table-responsive ">
                    <div className="dataTables_wrapper dt-bootstrap4">
                      <div className="grid-6 pb-2">

                        <div>
                        <label> Search Name</label>
                          <div className="input-group input-group-sm">
                            <input
                              className="form-control float-right"
                              placeholder="Search Name"
                              onChange={onChangeSearchName}
                            />
                          </div>
                        {/* <label> Status</label>
                          <select
                            className="form-control-sm select2"
                            style={{ width: "100%" }}
                            onChange={handleStatus}
                          >
                            <option default="selected" value="">
                              Default
                            </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select> */}
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="grid-2 pb-2">
                        <div className="left">
                          <div className="dt-buttons btn-group flex-wrap">
                            <select
                              className="form-control-sm select2"
                              style={{ width: "100%" }}
                              onChange={handleLimit}
                            >
                              <option default="selected" value="10">
                                10
                              </option>
                              <option value="50">50</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                        <div className="right">
                          <Link to="/admin/roles-add">
                            <button
                              type="button"
                              className="btn bg-gradient-primary btn-sm"
                            >
                              <i className="fas fa-plus-circle nav-icon"></i>{" "}
                              ADD
                            </button>
                          </Link>
                        </div>
                      </div>
                      <Loadings loading={loading}>
                        <div className="row">
                          <div className="col-sm-12">
                            <table className="table table-bordered table-hover">
                              <thead>
                                <tr className="bg-gradient-primary">
                                  <th>ID</th>
                                  <th>Name</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ListRole.length > 0 ? (
                                  ListRole.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{index + 1}</td>
                                      <td>
                                      {item.name}
                                      </td>
                                      <td>
                                        <Link
                                          to={`/admin/roles-update/${item.id}`}
                                          className="btn bg-primary btn-sm mr-1"
                                        >
                                          Update
                                        </Link>
                                        <Link
                                          to={`/admin/roles-permission/${item.id}`}
                                          className="btn bg-success btn-sm mr-1"
                                        >
                                          Permission
                                        </Link>
                                        <button
                                          type="button"
                                          className="btn bg-danger btn-sm "
                                          onClick={() => DeleteItem(item.id)}
                                        >
                                          Delete
                                        </button>
                                       
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="10">
                                      <p>No role available.</p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Loadings>
                      <div className="row">
                        <DataTableInfo filter={filter} totalRecords={totalRecords} />
                        <Pagination filter={filter} handlePageChange={handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ListRoles;
