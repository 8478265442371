import dayjs from "dayjs";

export const isEmptyOrNull = (value) => {
  return value === "" || value === null || value === "null" || value === undefined || value === "undefined";
};

export const formatDateClient = (date) => {
  if (!isEmptyOrNull(date)) {
    return dayjs(date).format("DD/MM/YYYY");
  }
  return null;
};

export const formatDateServer = (date) => {
  if (!isEmptyOrNull(date)) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  return null;
};

export const formatTimeServer = (time) => {
  if (!isEmptyOrNull(time)) {
    return dayjs("0000-00-00 " + time).format("HH:mm:ss");
  }
  return null;
};

export const formatTimeClient = (time) => {
  if (!isEmptyOrNull(time)) {
    return dayjs("0000-00-00 " + time).format("h:mm a");
  }
  return null;
};

// Utility to set a cookie
// const setCookie = (name, value, days) => {
//   const expires = days
//     ? "; expires=" + new Date(Date.now() + days * 864e5).toUTCString()
//     : "";
//   document.cookie = name + "=" + encodeURIComponent(value || "") + expires + "; path=/";
// };
const setCookie = (name, value, hours = 0, minutes = 0) => {
  const expirationTime = (hours * 60 + minutes) * 60 * 1000; // Convert hours and minutes to milliseconds
  const expires = expirationTime
    ? "; expires=" + new Date(Date.now() + expirationTime).toUTCString()
    : "";
  document.cookie = name + "=" + encodeURIComponent(value || "") + expires + "; path=/";
};
// Utility to get a cookie by name
const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
};

export const setUser = (user) => {
  setCookie("user", JSON.stringify(user),8,0); // Set cookie for 1 days
};

export const getUser = () => {
  const user = getCookie("user");
  return !isEmptyOrNull(user) ? JSON.parse(user) : null;
};

export const setRoleMenu = (permison_menu) => {
  setCookie("permison_menu", JSON.stringify(permison_menu),8,0);
};

export const getRoleMenu = () => {
  const permison_menu = getCookie("permison_menu");
  return !isEmptyOrNull(permison_menu) ? JSON.parse(permison_menu) : null;
};

export const setIsLogin = (value) => {
  setCookie("is_login", value,8,0);
};

export const getIsLogin = () => {
  const isLogin = getCookie("is_login");
  return isLogin === "1";
};

export const setAccessToken = (access_token) => {
  setCookie("access_token", access_token,8,0);
};

export const getAccessToken = () => {
  return getCookie("access_token");
};

export const setRefreshToken = (refresh_token) => {
  setCookie("refresh_token", refresh_token,8,0);
};

export const getRefreshToken = () => {
  return getCookie("refresh_token");
};

export const setUserConfig = (config) => {
  setCookie("config", JSON.stringify(config),8,0);
};

export const getUserConfig = () => {
  const config = getCookie("config");
  return !isEmptyOrNull(config) ? JSON.parse(config) : null;
};

export const logout = () => {
  const removeCookie = (name) => {
    document.cookie = name + "=; Max-Age=-99999999; path=/";
  };
  removeCookie("user");
  removeCookie("is_login");
  removeCookie("access_token");
  removeCookie("refresh_token");
  window.location.href = "/login";
};
export const setAlert= (value) => {
  setCookietime("loginSuccess", value, 1);
};
export const getAlert = () => {
  return getCookie("loginSuccess");
};
function setCookietime(name, value, seconds) {
  const date = new Date();
  date.setTime(date.getTime() + (seconds * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}