import axios from "axios";
import {getAccessToken, getRefreshToken,setAccessToken, setRefreshToken,logout } from "./service";
// const base_url = "http://localhost:8081/api/";
const base_url = "https://b-fishing369.2m-sys.com/api/";

export const request = (url = "", method = "get", data = {}, new_access_token = null)=>{
  var queryParam = "";
  if (method === "get" && Object.keys(data).length > 0) {
    Object.keys(data).forEach((key, index) => {
      queryParam += (index === 0 ? "?" : "&") + key + "=" + data[key];
    });
  }
  
    var access_token = getAccessToken();
    if (new_access_token) {
      access_token = new_access_token;
    }
    var headers = { "Content-Type": "application/json" };
    if (data instanceof FormData) {
      headers = { "Content-Type": "multipart/form-data" };
    }
    return axios({
            url: base_url + url + queryParam,
            method: method,
            data: data,
            headers: {
                ...headers,
                Authorization: "Bearer " + access_token,
            },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
        var response = error.response;
        if (response?.status === 401) {
          if (response?.data?.error?.name === "TokenExpiredError") {
            const refesh_token = getRefreshToken();
            return axios({
              url: base_url + "user/refresh_token",
              method: "post",
              data: {
                refres_token: refesh_token,
              },
            })
              .then((res) => {
                setAccessToken(res?.data?.access_token);
                setRefreshToken(res?.data?.refresh_token);
                var new_access_token_ofter_resfresh = res?.data?.access_token;
                return request(url, method, data, new_access_token_ofter_resfresh);
              })
              .catch((error1) => {
                logout(); //when try refresh not success then logout
              });
          } else if (response?.data?.error) {
            logout();
          }
        }
        console.log(response);
        
    });
}