import React from 'react';
import '../layouts/ImagePopup.css'; // For styling

const ImagePopup = ({ image, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <img src={image} alt="Popup Preview" className="popup-image" />
      </div>
    </div>
  );
};

export default ImagePopup;
