import React, { useEffect,useState} from "react";
import "./Dashboard.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import { request } from "../../util/request";
const Dashboard = () => {
  const [ListBanner, setListBanner] = useState([]);
  const [ListProduct, setListProduct] = useState([]);
  useEffect(() => {
    getBanners();
    getProducts();
  }, []);
  const getBanners = async () => {
    const response = await request("bannerjson", "get");
     if(response.data.status){
      setListBanner(response.data.data);
     }
  };
  const getProducts = async () => {
    const response = await request("productjson", "get");
     if(response.data.status){
      setListProduct(response.data.data);
     }
  };
  return (
    <div>
      <div class="bubbles">
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
      </div>
      <div className="gAZQBi"></div>
      <div className="container mt-1">
        <div className="row-grid-2">
          <div>
            <div className="logo">
              <img
                src="https://d2ajue4o5x1lc3.cloudfront.net/content/fishing369/images/logo.png"
                alt="fishing"
              />
            </div>
          </div>
        </div>
        <div className="block-banner mt-2">
          <Swiper
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
          {ListBanner.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={item.img_url_en} alt={item.name_en} />
            </SwiperSlide>
          ))}
          </Swiper>
        </div>
        <div className="companyname"> FISHING</div>
        <div>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={4}
            coverflowEffect={{
              rotate: 35,
              stretch: 0,
              depth: 200,
              modifier: 0.75,
              slideShadows: true,
            }}
            autoplay={{
              delay: 1200,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[EffectCoverflow, Autoplay]}
            className="demo-game"
          >
            
            {ListProduct.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.img_url_en} alt={item.name_en} />
              </SwiperSlide>
            ))}
          
          </Swiper>
        </div>
        <div className="companyname">Video Play Demo </div>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/Rj05PILG9ts?si=D8fOP8KSWIcKZWYA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
        <footer>
          <div className="grid-footer-2 mt-2">
            <div className="div-logo">
            <img
              className="footer-logo"
              src="https://d2ajue4o5x1lc3.cloudfront.net/content/fishing369/images/logo.png"
              alt="footer-logo"
            />
            </div>
            <div>
            <div className="provider-list-box">
               <div data-gp="ACEWIN">
                  <img
                    alt="ACEWIN"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/acewin.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="ADVANTPLAY">
                  <img
                    alt="ADVANTPLAY"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/advantplay.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="AG">
                  <img
                    alt="AG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/ag.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="ALLBET">
                  <img
                    alt="ALLBET"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/allbet.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="AMEBA">
                  <img
                    alt="AMEBA"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/ameba.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="AUX">
                  <img
                    alt="AUX"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/aux.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="BBIN">
                  <img
                    alt="BBIN"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/bbin.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="BNG">
                  <img
                    alt="BNG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/bng.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="BTGAMING">
                  <img
                    alt="BTGAMING"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/btgaming.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="BTI">
                  <img
                    alt="BTI"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/bti.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="CARD365">
                  <img
                    alt="CARD365"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/card365.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="CMDBET">
                  <img
                    alt="CMDBET"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/cmdbet.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="CQ9">
                  <img
                    alt="CQ9"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/cq9.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="DB_LIVE">
                  <img
                    alt="DB_LIVE"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/db_live.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="DG">
                  <img
                    alt="DG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/dg.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="EVO">
                  <img
                    alt="EVO"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/evo.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="EVOPLAY">
                  <img
                    alt="EVOPLAY"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/evoplay.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="EZUGI">
                  <img
                    alt="EZUGI"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/ezugi.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="FASTSPIN">
                  <img
                    alt="FASTSPIN"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/fastspin.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="FC">
                  <img
                    alt="FC"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/fc.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="FUNTA">
                  <img
                    alt="FUNTA"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/funta.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="GA28">
                  <img
                    alt="GA28"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/ga28.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="GPI">
                  <img
                    alt="GPI"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/gpi.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="GW">
                  <img
                    alt="GW"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/gw.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="HACKSAW">
                  <img
                    alt="HACKSAW"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/hacksaw.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="IM_SPORTS">
                  <img
                    alt="IM_SPORTS"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/im_sports.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="JDB">
                  <img
                    alt="JDB"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/jdb.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="JILI">
                  <img
                    alt="JILI"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/jili.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="KA">
                  <img
                    alt="KA"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/ka.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="KINGMAKER">
                  <img
                    alt="KINGMAKER"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/kingmaker.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="LC">
                  <img
                    alt="LC"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/lc.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="LUCKY_SPORTS">
                  <img
                    alt="LUCKY_SPORTS"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/lucky_sports.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="MG">
                  <img
                    alt="MG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/mg.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="MOTIVATION">
                  <img
                    alt="MOTIVATION"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/motivation.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="NLC">
                  <img
                    alt="NLC"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/nlc.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="OD_COCK">
                  <img
                    alt="OD_COCK"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/od_cock.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="OD_GAMING">
                  <img
                    alt="OD_GAMING"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/od_gaming.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="ONLIVE">
                  <img
                    alt="ONLIVE"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/onlive.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PG">
                  <img
                    alt="PG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/pg.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PINNACLE">
                  <img
                    alt="PINNACLE"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/pinnacle.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PLAYSON">
                  <img
                    alt="PLAYSON"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/playson.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PNG">
                  <img
                    alt="PNG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/png.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PP">
                  <img
                    alt="PP"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/pp.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PS">
                  <img
                    alt="PS"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/ps.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="PT">
                  <img
                    alt="PT"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/pt.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="RCB">
                  <img
                    alt="RCB"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/rcb.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="RELAX">
                  <img
                    alt="RELAX"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/relax.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="RICH88">
                  <img
                    alt="RICH88"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/rich88.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="RSG">
                  <img
                    alt="RSG"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/rsg.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="SA">
                  <img
                    alt="SA"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/sa.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="SABA">
                  <img
                    alt="SABA"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/saba.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="SBOBET">
                  <img
                    alt="SBOBET"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/sbobet.png"
                    loading="lazy"
                  />
                </div>
                <div data-gp="SEXYBCRT">
                  <img
                    alt="SEXYBCRT"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/sexybcrt.png"
                    loading="lazy"
                  />
                </div>
              
                <div data-gp="SV">
                  <img
                    alt="SV"
                    src="https://img.ihudba.com/img/static/gplogo/h-dark/sv.png"
                    loading="lazy"
                  />
                </div>
           
            </div>
            </div>
          </div>
        </footer>
        <div className="event-qmenu">
          
          <div className="cont">
            <div className="event-item">
              <img
                src="https://res.cloudinary.com/dfcuejg3y/image/upload/v1726812275/hemzq1mzy4xofvcg35gh.png"
                alt="event-mission"
              />
            </div>
            <div className="event-item">
              <img
                src="https://res.cloudinary.com/dfcuejg3y/image/upload/v1726812275/elrie2sauplxli8tglud.png"
                alt="event-vip"
              />
            </div>
            <div className="event-item" >
              <img
                src="https://res.cloudinary.com/dfcuejg3y/image/upload/v1726812275/mqdfm2gi3mgglkv3z6gq.png"
                alt="event-roulette"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
