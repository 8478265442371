import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { getAlert } from '../../../util/service';
const Home = () => {
  useEffect(() => {
    const loginSuccess = getAlert('loginSuccess');
      if (loginSuccess === 'true') {
          toast.success('Login is succeed!');
          localStorage.removeItem('loginSuccess'); // Clear the flag
      }
  }, []);
  return (
    <div>
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Dashboard</h1>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  </div>
  );
};

export default Home;
