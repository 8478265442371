import { Link ,useNavigate,useParams  } from "react-router-dom";
import React, { useState ,useEffect,useCallback } from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Update = () => {
    const { id } = useParams();
    const [nameKH, setnameKH] = useState('');
    const [nameEN, setnameEN] = useState('');
    const [imgLinkKH, setimgLinkKH] = useState('');
    const [imgLinkEN, setimgLinkEN] = useState('');
    const [status, setstatus] = useState('');
    const navigate = useNavigate();
    const getBanner = useCallback(async () => {
        const response = await request(`banner/${id}`, "get");
        if (response.data.status) {
            const banner = response.data.data[0]; // Adjust according to the response structure
            setnameKH(banner.name_kh || '');
            setnameEN(banner.name_en || '');
            setimgLinkKH(banner.img_url_kh || '');
            setimgLinkEN(banner.img_url_en || '');
            setstatus(banner.status || '');
        }
    }, [id]);
    useEffect(() => {
        getBanner();
    }, [getBanner]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        var param = {
        'id'         :id,
        'name_kh'    :nameKH,
        'name_en'    :nameEN,
        'img_url_kh' :imgLinkKH,
        'img_url_en' :imgLinkEN,
        'status'     :status,
        };
        const response = await request("banner/update", "put", param);
        if (response.data.message) {
        Swal.fire({
            title: "Good job!",
            text: response.data.message,
            icon: "success"
        });
        navigate("/admin/banners-list");
        }else if (response.data.error) {
        if (response.data.error.Name_kh) {
            Swal.fire({
            title: "Please Check Information Banner",
            text: response.data.error.Name_kh,
            icon: "error"
            });
        }
        if (response.data.error.Name_en) {
            Swal.fire({
            title: "Please Check Information Banner",
            text: response.data.error.Name_en,
            icon: "error"
            });
        }
        
        }
    };
    return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Banner</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      Please fill in the information below. The field labels
                      marked with * are required input fields.
                    </h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                        <div className="grid-2 pb-2">
                          <div className="left">
                          <h3 className="m-0 text-primary">Information</h3>
                          </div>
                          <div className="right">
                            <Link to="/admin/banners-list">
                              <button
                                type="button"
                                className="btn bg-gradient-primary btn-sm"
                              >
                                <i className="fas fa-arrow-left nav-icon"></i>{" "}
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="row">

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Name (KH) <span className="text-danger text-unit">*</span> </label>
                                <input type="text" className="form-control" placeholder="Enter Name (KH)"  value={nameKH} onChange={(e) => setnameKH(e.target.value)} required />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                  <label> Name (EN) <span className="text-danger text-unit">*</span></label>
                                  <input type="text" className="form-control" placeholder="Enter Name (EN)" value={nameEN} onChange={(e) => setnameEN(e.target.value)} required/>
                              </div>
                            </div>

                            <div className="col-sm-4">
                              <div className="form-group">
                                <label> Img Link (KH) <span className="text-danger text-unit">*</span></label>
                                <input type="text" className="form-control" placeholder="Enter Img Link (KH)" value={imgLinkKH} onChange={(e) => setimgLinkKH(e.target.value)} required />
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                              <center><label> Img Link (KH) Preview</label></center>
                                <center><img src={imgLinkKH} style={{ width: "100px",height: "50px" }} alt={imgLinkKH} /></center>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                  <label> Img Link (EN) <span className="text-danger text-unit">*</span></label>
                                  <input type="text" className="form-control" placeholder="Enter Img Link (EN)" value={imgLinkEN} onChange={(e) => setimgLinkEN(e.target.value)} required/>
                              </div>
                            </div>
                            <div className="col-sm-2">
                              <div className="form-group">
                              <center><label> Img Link (EN) Preview</label></center>
                                <center><img src={imgLinkEN} style={{ width: "100px",height: "50px" }} alt={imgLinkEN} /></center>
                              </div>
                            </div>
                            

                        </div>
                      
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Update;
