import React, { useState } from 'react';
import { request } from '../../util/request';

import { setAccessToken, setIsLogin, setRefreshToken, setUser ,setAlert} from "../../util/service";
const Login = () => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    var param = {
      'username':username,
      'password':password
    };
    const res = await request("user/login", "post", param);
    if (res.data.message) {
      setUser(JSON.stringify(res.data.user));
      setIsLogin("1");
      setAccessToken(res.data.access_token);
      setRefreshToken(res.data.refresh_token);
      window.location.href = "admin/dashboard";
      setAlert(true);
    } else if (res.data.error) {
      if (res.data.error.Username) {
        setMessage(res.data.error.Username);
      }
      if (res.data.error.Password) {
        setMessage(res.data.error.Password);
      }
    }
    
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <div className="h1"><b>2M</b>Solution</div>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form onSubmit={handleLogin}>
              <div className="input-group mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Username" 
                  value={username}  
                  onChange={(e) => setEmail(e.target.value)} 
                  required 
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input 
                  type="password" 
                  className="form-control" 
                  placeholder="Password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  required 
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              {message && <div className="alert alert-danger">{message}</div>}
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                </div>
              </div>
            </form>
            <div className="social-auth-links text-center mt-2 mb-3">
              <div className="btn btn-block btn-primary">
                <i className="fab fa-facebook mr-2" /> Sign in using Facebook
              </div>
              <div className="btn btn-block btn-danger">
                <i className="fab fa-google-plus mr-2" /> Sign in using Google+
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Login;
