const DataTableInfo = ({ filter, totalRecords }) => {
  return (
    <div className="col-sm-12 col-md-5">
      <div className="dataTables_info">
        Showing {(filter.current.filterpage - 1) * filter.current.filterlimit + 1} to { Math.min( filter.current.filterpage * filter.current.filterlimit,totalRecords)} of {totalRecords} entries
      </div>
    </div>
  );
};
export default DataTableInfo;
