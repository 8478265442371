const Pagination = ({ filter, handlePageChange }) => {
  return (
    <div className="col-sm-12 col-md-7">
      <div className="dataTables_paginate paging_simple_numbers">
        <ul className="pagination">
          <li
            className={`paginate_button page-item previous ${
              filter.current.filterpage === 1 ? "disabled" : ""
            }`}
            onClick={() => handlePageChange(filter.current.filterpage - 1)}
          >
            <div className="page-link div-a">Previous</div>
          </li>
          {[...Array(filter.current.totalpage)].map((_, index) => (
            <li
              key={index}
              className={`paginate_button page-item ${
                filter.current.filterpage === index + 1 ? "active" : ""
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              <div className="page-link div-a">{index + 1}</div>
            </li>
          ))}
          <li
            className={`paginate_button page-item next ${
              filter.current.filterpage === filter.current.totalpage
                ? "disabled"
                : ""
            }`}
            onClick={() => handlePageChange(filter.current.filterpage + 1)}
          >
            <div className="page-link div-a">Next</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
