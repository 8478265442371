const Dashboard = () => {
  return (
    <div>
      <footer className="main-footer">
        <strong>
          Copyright © 2024 <a href="http://2m-solution.io">2M-Solution.io </a>
        </strong>
        All rights reserved.
      </footer>
    </div>
  );
};
export default Dashboard;
