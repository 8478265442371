import { Link ,useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Add = () => {
  const [ImgLogo, setImgLogo] = useState('');
  const [ImgQr, setImgQr] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    var param = {
      'img_logo_url'    :ImgLogo,
      'img_qr_url'      :ImgQr,
    };
    const response = await request("bank/create", "post", param);
    if (response.data.message) {
      Swal.fire({
        title: "Good job!",
        text: response.data.message,
        icon: "success"
      });
      navigate("/admin/banks-list");
    }else if (response.data.error) {
      if (response.data.error.img_logo_url) {
        Swal.fire({
          title: "Please Check Information Bank",
          text: response.data.error.img_logo_url,
          icon: "error"
        });
      }
    }
  };
  return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add Bank</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      Please fill in the information below. The field labels
                      marked with * are required input fields.
                    </h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                        <div className="grid-2 pb-2">
                          <div className="left">
                          <h3 className="m-0 text-primary">Information</h3>
                          </div>
                          <div className="right">
                            <Link to="/admin/banks-list">
                              <button
                                type="button"
                                className="btn bg-gradient-primary btn-sm"
                              >
                                <i className="fas fa-arrow-left nav-icon"></i>{" "}
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="row">

                            <div className="col-sm-12">
                              <div className="form-group">
                                <label> Img Logo Link <span className="text-danger text-unit">*</span> </label>
                                <input type="text" className="form-control" placeholder="Enter Img Logo Link"  value={ImgLogo} onChange={(e) => setImgLogo(e.target.value)} required />
                              </div>
                            </div>

                            <div className="col-sm-12">
                              <div className="form-group">
                                <label> Img Qr Link</label>
                                <input type="text" className="form-control" placeholder="Enter Img Qr Link"  value={ImgQr} onChange={(e) => setImgQr(e.target.value)} />
                              </div>
                            </div>

                        </div>
                      
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add;
