import { Link ,useNavigate} from "react-router-dom";
import React, { useEffect,useRef, useState } from "react";
import { request } from "../../../../util/request";
import Loadings from "..//../Loading";
import Pagination  from "..//../Pagination";
import DataTableInfo  from "..//../DataTableInfo";
import Swal from "sweetalert2";
const ListUsers = () => {
  const navigate = useNavigate();
  const [ListUser, setListUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [Id, setId] = useState('');
  const [Password, setPassword] = useState('');

  const filter = useRef({
    filterStatus: "",
    filterpage: 1,
    filterlimit: 10,
    totalpage: "",
  });
  useEffect(() => {
    getUsers();
  }, []);
  
  const getUsers = async () => {
    setLoading(true);
    var param = {
      filterStatus: filter.current.filterStatus,
      filterpage: filter.current.filterpage,
      filterlimit: filter.current.filterlimit,
    };
    
    const response = await request("user/getlist", "get", param);
    setLoading(false);
    if (response) {
      setListUser(response.data.data);
      if (filter.current.filterpage === 1) {
        setTotalRecords(response.data.totalRecords);
        const pages = Math.ceil(response.data.totalRecords / filter.current.filterlimit);
        filter.current.totalpage = pages;
      }
    }
  };
  const handleStatus = (event) => {
    filter.current.filterStatus = event.target.value;
    getUsers();
  };
  const handleLimit = (event) => {
    filter.current.filterlimit = event.target.value;
    getUsers();
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= filter.current.totalpage) {
      filter.current.filterpage = pageNumber;
      getUsers();
    }
  };
  const ActiveClick = (item, status) => {
    Swal.fire({
      title: status === 1 ? "Deactivate Item?" : "Activate Item?",
      text: "Are you sure you want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        status = status === 1 ? 0 : 1;
        const param = {
          id: item,
          status: status,
        };
        const res = await request("userstatus/", "put", param);
        if (res) {
          getUsers();
        }
        Swal.fire(
          "Changed!",
          `The item is now ${status === 0 ? "Inactive" : "Active"}.`,
          "success"
        );
      }
    });
  };
  const handleShow = (id) => {
    setId(id);
    setShowModal(true);
  };
  const DeleteItem = (itemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await request("user/delete/" + itemId, "delete");
        if (res) {
          getUsers();
        }
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    var param = {
      'id'          :Id,
      'password'    :Password,
    };
    const response = await request("user/change_password", "put", param);
    if (response.data.message) {
      Swal.fire({
        title: "Good job!",
        text: response.data.message,
        icon: "success"
      });
      navigate("/admin/users-list");
      setShowModal(false);
    }
    else if (response.data.error) {
      if (response.data.error.Password) {
        Swal.fire({
          title: "Please Check Information User",
          text: response.data.error.Password,
          icon: "error"
        });
      }
    }
  };
  return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">List Users</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                    Please use the table below to navigate or filter the results. You can download the table as excel and pdf.
                    </h5>
                  </div>
                  <div className="card-body table-responsive ">
                    <div className="dataTables_wrapper dt-bootstrap4">
                      <div className="grid-6 pb-2">

                        <div>
                        <label> Status</label>
                          <select
                            className="form-control-sm select2"
                            style={{ width: "100%" }}
                            onChange={handleStatus}
                          >
                            <option default="selected" value="">
                              Default
                            </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="grid-2 pb-2">
                        <div className="left">
                          <div className="dt-buttons btn-group flex-wrap">
                            <select
                              className="form-control-sm select2"
                              style={{ width: "100%" }}
                              onChange={handleLimit}
                            >
                              <option default="selected" value="10">
                                10
                              </option>
                              <option value="50">50</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                        <div className="right">
                          <Link to="/admin/users-add">
                            <button
                              type="button"
                              className="btn bg-gradient-primary btn-sm"
                            >
                              <i className="fas fa-plus-circle nav-icon"></i>{" "}
                              ADD
                            </button>
                          </Link>
                        </div>
                      </div>
                      <Loadings loading={loading}>
                        <div className="row">
                          <div className="col-sm-12">
                            <table className="table table-bordered table-hover">
                              <thead>
                                <tr className="bg-gradient-primary">
                                  <th>ID</th>
                                  <th>Username</th>
                                  <th>Role</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ListUser.length > 0 ? (
                                  ListUser.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>{index + 1}</td>
                                      {/* <td>{item.id}</td> */}
                                      <td>
                                      {item.username}
                                      </td>
                                      <td>
                                      {item.RoleName}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className={`btn btn-block btn-sm ${
                                            item.status === 1
                                              ? "bg-success"
                                              : "bg-danger"
                                          }`}
                                          onClick={() =>
                                            ActiveClick(item.id, item.status)
                                          }
                                        >
                                          {item.status === 1
                                            ? "Active"
                                            : "Inactive"}
                                        </button>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/admin/users-update/${item.id}`}
                                          className="btn bg-primary btn-sm mr-1"
                                        >
                                          Update
                                        </Link>
                                        <button
                                          type="button"
                                          className="btn bg-danger btn-sm mr-1"
                                          onClick={() => DeleteItem(item.id)}
                                        >
                                          Delete
                                        </button>
                                        <button
                                          type="button"
                                          className="btn bg-info btn-sm"
                                          onClick={() => handleShow(item.id)}
                                        >
                                          Password
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="10">
                                      <p>No user available.</p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Loadings>
                      <div className="row">
                        <DataTableInfo filter={filter} totalRecords={totalRecords} />
                        <Pagination filter={filter} handlePageChange={handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
          <div className="modal-dialog">
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-group">
                  <label> New Password <span className="text-danger text-unit">*</span> </label>
                  <input type="password" className="form-control" placeholder="New Password" required value={Password} onChange={(e) => setPassword(e.target.value)} />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleClose}>
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
            </form>
          </div>
        </div>
      )}
      {showModal && <div className="modal-backdrop fade show" onClick={handleClose}></div>}
    </div>
  );
};

export default ListUsers;
