import {logout,getUser} from "../../util/service";
const Header = () => {
  const user = JSON.parse(getUser());
  return (
    <div>
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <div className="nav-link div-a" data-widget="pushmenu"><i className="fas fa-bars" /></div>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown user-menu">
          <a href="https://example.com" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
            <img  src={`/img/AdminLTELogo.png`} className="user-image img-circle elevation-2" alt="checkinme" />
            <span className="d-none d-md-inline">{user.username}</span>
          </a>
          <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: 'inherit', right: 0, borderRadius: 5, overflow: 'hidden', padding: 15, paddingBottom: 10, backgroundColor: '#f8f9fa' }}>
            <li className="user-header bg-primary" style={{ borderRadius: 5 }}>
              <img  src={`/img/AdminLTELogo.png`} className="img-circle elevation-2" alt="checkinme" />
              <p>{user.username}</p>
            </li>
            <li className="user-footer">
              <div className="row">
                <div className="col-md-12">
                  <a href="https://example.com" className="btn btn-default btn-flat">User Profile</a>
                  <div onClick={logout} className="div-a btn btn-default btn-flat float-right">
                    Sign Out
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>

      </ul>
    </nav>
  </div>
  );
};

export default Header;
