import { Link } from "react-router-dom";
import React, { useEffect,useRef, useState } from "react";
import { request } from "../../../../util/request";
import Loadings from "..//../Loading";
import Pagination  from "..//../Pagination";
import DataTableInfo  from "..//../DataTableInfo";
import ImagePopup from '..//..//../layouts/PreviewIMG';
import Swal from "sweetalert2";
const ListBanners = () => {
  const [ListBanner, setListBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [imageToShow, setImageToShow] = useState('');

  const openPopup = (imageUrl) => {
    setImageToShow(imageUrl);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const filter = useRef({
    filterNameKHoEN: "",
    filterStatus: "",
    filterpage: 1,
    filterlimit: 10,
    totalpage: "",
  });
  useEffect(() => {
    getBanners();
  }, []);
  
  const getBanners = async () => {
    setLoading(true);
    var param = {
      filterNameKHoEN: filter.current.filterNameKHoEN,
      filterStatus: filter.current.filterStatus,
      filterpage: filter.current.filterpage,
      filterlimit: filter.current.filterlimit,
    };
    
    const response = await request("banner/getlist", "get", param);
    setLoading(false);
    if (response) {
      setListBanner(response.data.data);
      if (filter.current.filterpage === 1) {
        setTotalRecords(response.data.totalRecords);
        const pages = Math.ceil(response.data.totalRecords / filter.current.filterlimit);
        filter.current.totalpage = pages;
      }
    }
  };
  const onChangeSearchNameKHoEN = (event) => {
    filter.current.filterNameKHoEN = event.target.value;
    getBanners();
  };
  const handleStatus = (event) => {
    filter.current.filterStatus = event.target.value;
    getBanners();
  };
  const handleLimit = (event) => {
    filter.current.filterlimit = event.target.value;
    getBanners();
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= filter.current.totalpage) {
      filter.current.filterpage = pageNumber;
      getBanners();
    }
  };
  const ActiveClick = (item, status) => {
    Swal.fire({
      title: status === 1 ? "Deactivate Item?" : "Activate Item?",
      text: "Are you sure you want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        status = status === 1 ? 0 : 1;
        const param = {
          id: item,
          status: status,
        };
        const res = await request("bannerstatus/", "put", param);
        if (res) {
          getBanners();
        }
        Swal.fire(
          "Changed!",
          `The item is now ${status === 0 ? "Inactive" : "Active"}.`,
          "success"
        );
      }
    });
  };
  const DeleteItem = (itemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await request("banner/delete/" + itemId, "delete");
        if (res) {
          getBanners();
        }
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };
  return (
  <div>
    <ImagePopup image={imageToShow} isOpen={isPopupOpen} onClose={closePopup} />
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">List Banner</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                    Please use the table below to navigate or filter the results. You can download the table as excel and pdf.
                    </h5>
                  </div>
                  <div className="card-body table-responsive ">
                    <div className="dataTables_wrapper dt-bootstrap4">
                      <div className="grid-6 pb-2">
                        <div>
                        <label> Search Name ( KH & EN )</label>
                          <div className="input-group input-group-sm">
                            <input
                              className="form-control float-right"
                              placeholder="Search Name"
                              onChange={onChangeSearchNameKHoEN}
                            />
                          </div>
                        </div>
                        <div>
                        <label> Status</label>
                          <select
                            className="form-control-sm select2"
                            style={{ width: "100%" }}
                            onChange={handleStatus}
                          >
                            <option default="selected" value="">
                              Default
                            </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <div></div>
                        <div></div>
                      </div>
                      <div className="grid-2 pb-2">
                        <div className="left">
                          <div className="dt-buttons btn-group flex-wrap">
                            <select
                              className="form-control-sm select2"
                              style={{ width: "100%" }}
                              onChange={handleLimit}
                            >
                              <option default="selected" value="10">
                                10
                              </option>
                              <option value="50">50</option>
                              <option value="500">500</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                        <div className="right">
                          <Link to="/admin/banners-add">
                            <button
                              type="button"
                              className="btn bg-gradient-primary btn-sm"
                            >
                              <i className="fas fa-plus-circle nav-icon"></i>{" "}
                              ADD
                            </button>
                          </Link>
                        </div>
                      </div>
                      <Loadings loading={loading}>
                        <div className="row">
                          <div className="col-sm-12">
                            <table className="table table-bordered table-hover">
                              <thead>
                                <tr className="bg-gradient-primary">
                                  <th>ID</th>
                                  <th>Name Kh</th>
                                  <th>Name En</th>
                                  <th>Img Link Kh</th>
                                  <th>Img Link En</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ListBanner.length > 0 ? (
                                  ListBanner.map((item, index) => (
                                    <tr key={item.id}>
                                      {/* <td>{index + 1}</td> */}
                                      <td>{item.id}</td>
                                      <td>{item.name_kh}</td>
                                      <td>{item.name_en}</td>
                                      <td>
                                        <img
                                        onClick={() => openPopup(item.img_url_kh ? item.img_url_kh : "/img/null.png")}
                                          src={item.img_url_kh ? item.img_url_kh : "/img/null.png"}
                                          onError={(e) => {  e.target.src = "/img/null.png";}}
                                          alt={item.name_kh}
                                          style={{ width: "30px" }}
                                        />
                                      </td>
                                      <td>
                                        <img
                                        onClick={() => openPopup(item.img_url_en ? item.img_url_en : "/img/null.png")}
                                          src={item.img_url_en ? item.img_url_en : "/img/null.png" }
                                          onError={(e) => {  e.target.src = "/img/null.png";}}
                                          alt={item.name_en}
                                          style={{ width: "30px" }}
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className={`btn btn-block btn-sm ${
                                            item.status === 1
                                              ? "bg-success"
                                              : "bg-danger"
                                          }`}
                                          onClick={() =>
                                            ActiveClick(item.id, item.status)
                                          }
                                        >
                                          {item.status === 1
                                            ? "Active"
                                            : "Inactive"}
                                        </button>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/admin/banners-update/${item.id}`}
                                          className="btn bg-primary btn-sm mr-1"
                                        >
                                          Update
                                        </Link>
                                        <button
                                          type="button"
                                          className="btn bg-danger btn-sm"
                                          onClick={() => DeleteItem(item.id)}
                                        >
                                          Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="10">
                                      <p>No banners available.</p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Loadings>
                      <div className="row">
                        <DataTableInfo filter={filter} totalRecords={totalRecords} />
                        <Pagination filter={filter} handlePageChange={handlePageChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  );
};

export default ListBanners;
