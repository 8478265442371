import {Outlet} from "react-router-dom";
import React, { useEffect } from "react";
import {getIsLogin,getUser} from "../../util/service";
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
const App = () => {
  const user = getUser();
  useEffect(() => {
    if (!getIsLogin()) {
      window.location.href = "/login";
    }
  }, []);
  if (user == null) {
    return null;
  }
  return (
    <div className="wrapper">
      <Header/>
      <Sidebar/>
      <Outlet/>
      <Footer/>
    </div>
  );
};

export default App;
