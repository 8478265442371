import { Link ,useNavigate } from "react-router-dom";
import React, { useState,useEffect } from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Add = () => {
  const navigate = useNavigate();
  const [Username, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const [Option, setOption] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  useEffect(() => {
    getRoles();
  }, []);
  const getRoles = async () => {
    const response = await request("role/getlistjson", "get");
    if (response.data.status) {
      setOption(response.data.data);
    }
  };
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    var param = {
      'username'    :Username,
      'password'    :Password,
      'role'        :selectedRole,
    };
    const response = await request("user/create", "post", param);
    if (response.data.message) {
      Swal.fire({
        title: "Good job!",
        text: response.data.message,
        icon: "success"
      });
      navigate("/admin/users-list");
    }else if (response.data.error) {
      if (response.data.error.Username) {
        Swal.fire({
          title: "Please Check Information User",
          text: response.data.error.Username,
          icon: "error"
        });
      }
      if (response.data.error.Password) {
        Swal.fire({
          title: "Please Check Information User",
          text: response.data.error.Password,
          icon: "error"
        });
      }
      if (response.data.error.Role) {
        Swal.fire({
          title: "Please Check Information User",
          text: response.data.error.Role,
          icon: "error"
        });
      }
    }
  };
  return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add User</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      Please fill in the information below. The field labels
                      marked with * are required input fields.
                    </h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                        <div className="grid-2 pb-2">
                          <div className="left">
                          <h3 className="m-0 text-primary">Information</h3>
                          </div>
                          <div className="right">
                            <Link to="/admin/users-list">
                              <button
                                type="button"
                                className="btn bg-gradient-primary btn-sm"
                              >
                                <i className="fas fa-arrow-left nav-icon"></i>{" "}
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="row col-sm-6">

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Username <span className="text-danger text-unit">*</span> </label>
                                <input type="text" className="form-control" placeholder="Enter Username"  value={Username} onChange={(e) => setUsername(e.target.value)} required />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Password <span className="text-danger text-unit">*</span></label>
                                <input type="password" className="form-control" placeholder="Enter Password"  value={Password} onChange={(e) => setPassword(e.target.value)} />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                              <label> Role <span className="text-danger text-unit">*</span></label>
                                <select className="form-control" value={selectedRole} onChange={handleRoleChange}>
                                  <option value="">Please Select Role</option>
                                  {Option.map((role) => (
                                    <option key={role.id} value={role.id}>
                                      {role.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          

                        </div>
                      
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add;
