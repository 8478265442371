import { Link,useLocation} from "react-router-dom";
import { hasPermission } from "../../util/permissionUtils";
import React, { useEffect ,useState} from "react";
import { request } from "../../util/request";
import {getUser} from "../../util/service";
function ListCustomLink({ to, children }) {
  const location = useLocation();
  var extractedString = location.pathname.split('-')[0];
  const isActive = extractedString === to;
  return (
    <li className={isActive ? 'show active nav-item menu-open' : 'nav-item '}>
         {children}
    </li>
  );
}
function CustomLink({ to, children }) {
  const elements = document.querySelectorAll('.nav.nav-treeview');
  elements.forEach(element => {
    element.removeAttribute('style');
  });
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <Link to={to} className={isActive ? 'active nav-link' : 'nav-link'}>
      {children}
    </Link>
  );
}
function BlockActive({ to, children }) {
  const location = useLocation();
  var extractedString = location.pathname.split('-')[0];
  const isActive = extractedString === to;
  return (
    <div to={to} className={isActive ? 'active nav-link div-a' : 'nav-link div-a'}>
      {children}
    </div>
  );
}
function Sidebar() {
  const user = JSON.parse(getUser());
  useEffect(() => {
    getRoles();
  }, []);
  const [permission, setpermission] = useState([]);
  const getRoles = async () => {
    const response = await request(`permission/${user.roleid}`, "get");
    if (response.data.status) {
      setpermission(response.data.data)
    }
  };
  const users = {'permission':permission};
  const permissions = users?.permission || [];
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/" className="brand-link">
          <img
            src={`/img/AdminLTELogo.png`}
            alt="2M-Solution"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">2M-Solution</span>
        </Link>

        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <CustomLink to="/admin/dashboard">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p className="dashboard">
                    Dashboard
                  </p>
                </CustomLink>
              </li>
              {hasPermission(permissions, 'banners') && (
                <ListCustomLink to="/admin/banners">
                  <BlockActive className="nav-link" to="/admin/banners">
                    <i className="nav-icon fas fa-chalkboard" />
                    <p>
                      Banners
                      <i className="right fas fa-angle-left" />
                    </p>
                  </BlockActive>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      {hasPermission(permissions, 'banners-list') && (
                        <CustomLink to="/admin/banners-list" className="nav-link ">
                          <i className="fas fa-list nav-icon" />
                          <p>List Banners</p>
                        </CustomLink>
                      )}
                      {hasPermission(permissions, 'banners-add') && (
                        <CustomLink to="/admin/banners-add" className="nav-link ">
                          <i className="fas fa-plus-circle nav-icon"></i>
                          <p>Add Banners</p>
                        </CustomLink>
                      )}
                    </li>
                  </ul>
                </ListCustomLink>
              )}

            {hasPermission(permissions, 'products') && (
              <ListCustomLink to="/admin/products">
                <BlockActive className="nav-link" to="/admin/products">
                  <i className="nav-icon fab fa-product-hunt" />
                  <p>
                    Products
                    <i className="right fas fa-angle-left" />
                  </p>
                </BlockActive>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {hasPermission(permissions, 'products-list') && (
                      <CustomLink to="/admin/products-list" className="nav-link ">
                        <i className="fas fa-list nav-icon" />
                        <p>List Products</p>
                      </CustomLink>
                    )}
                    {hasPermission(permissions, 'products-add') && (
                      <CustomLink to="/admin/products-add" className="nav-link ">
                        <i className="fas fa-plus-circle nav-icon"></i>
                        <p>Add Products</p>
                      </CustomLink>
                     )}
                  </li>
                </ul>
              </ListCustomLink>
            )}
              {hasPermission(permissions, 'banks') && (
              <ListCustomLink to="/admin/banks">
                <BlockActive className="nav-link" to="/admin/banks">
                  <i className="nav-icon fas fa-university" />
                  <p>
                    Banks
                    <i className="right fas fa-angle-left" />
                  </p>
                </BlockActive>
                
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {hasPermission(permissions, 'banks-list') && (
                    <CustomLink to="/admin/banks-list" className="nav-link ">
                      <i className="fas fa-list nav-icon" />
                      <p>List Banks</p>
                    </CustomLink>
                    )}
                    {hasPermission(permissions, 'banks-add') && (
                    <CustomLink to="/admin/banks-add" className="nav-link ">
                      <i className="fas fa-plus-circle nav-icon"></i>
                      <p>Add Banks</p>
                    </CustomLink>
                    )}
                  </li>
                </ul>
              </ListCustomLink>
              )}
              {hasPermission(permissions, 'users') && (
              <ListCustomLink to="/admin/users">
                <BlockActive className="nav-link" to="/admin/users">
                  <i className="nav-icon fas fa-users" />
                  <p>
                    Users
                    <i className="right fas fa-angle-left" />
                  </p>
                </BlockActive>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                  {hasPermission(permissions, 'users-list') && (
                    <CustomLink to="/admin/users-list" className="nav-link ">
                      <i className="fas fa-list nav-icon" />
                      <p>List Users</p>
                    </CustomLink>
                  )}
                  {hasPermission(permissions, 'users-add') && (
                    <CustomLink to="/admin/users-add" className="nav-link ">
                      <i className="fas fa-plus-circle nav-icon"></i>
                      <p>Add Users</p>
                    </CustomLink>
                  )}
                  </li>
                </ul>
              </ListCustomLink>
              )}
              {hasPermission(permissions, 'roles') && (
                <ListCustomLink to="/admin/roles">
                  <BlockActive className="nav-link" to="/admin/roles">
                    <i className="nav-icon fas fa-user-shield"></i>
                    <p>
                      Roles
                      <i className="right fas fa-angle-left" />
                    </p>
                  </BlockActive>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                    {hasPermission(permissions, 'roles-list') && (
                      <CustomLink to="/admin/roles-list" className="nav-link ">
                        <i className="fas fa-list nav-icon" />
                        <p>List Roles</p>
                      </CustomLink>
                    )} 
                    {hasPermission(permissions, 'roles-add') && (
                      <CustomLink to="/admin/roles-add" className="nav-link ">
                        <i className="fas fa-plus-circle nav-icon"></i>
                        <p>Add Roles</p>
                      </CustomLink>
                    )}
                    </li>
                  </ul>
                </ListCustomLink>
              )}
            </ul>
          </nav>
          {/* /.sidebar-menu */}

        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}
export default Sidebar;
