import { Link ,useParams,useNavigate } from "react-router-dom";
import React, { useState ,useEffect,useCallback} from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Permission = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const getRole = useCallback(async () => {
      const response = await request(`permission/${id}`, "get");
      if (response.data.status) {
          setIsCheckedBanners(response.data.data[0].status)
          setIsCheckedBanners_List(response.data.data[1].status)
          setIsCheckedBanners_Add(response.data.data[2].status)
          setIsCheckedBanners_Update(response.data.data[3].status)
          setIsCheckedBanners_Delete(response.data.data[4].status)

          setIsCheckedProducts(response.data.data[5].status)
          setIsCheckedProducts_List(response.data.data[6].status)
          setIsCheckedProducts_Add(response.data.data[7].status)
          setIsCheckedProducts_Update(response.data.data[8].status)
          setIsCheckedProducts_Delete(response.data.data[9].status)

          setIsCheckedBanks(response.data.data[10].status)
          setIsCheckedBanks_List(response.data.data[11].status)
          setIsCheckedBanks_Add(response.data.data[12].status)
          setIsCheckedBanks_Update(response.data.data[13].status)
          setIsCheckedBanks_Delete(response.data.data[14].status)
      }
  }, [id]);
    useEffect(() => {
      getRole();
  }, [getRole]);

    const [IsCheckedBanners, setIsCheckedBanners] = useState(false);
    const [IsCheckedBanners_List, setIsCheckedBanners_List] = useState(false);
    const [IsCheckedBanners_Add, setIsCheckedBanners_Add] = useState(false);
    const [IsCheckedBanners_Update, setIsCheckedBanners_Update] = useState(false);
    const [IsCheckedBanners_Delete, setIsCheckedBanners_Delete] = useState(false);

    const [IsCheckedProducts, setIsCheckedProducts] = useState(false);
    const [IsCheckedProducts_List, setIsCheckedProducts_List] = useState(false);
    const [IsCheckedProducts_Add, setIsCheckedProducts_Add] = useState(false);
    const [IsCheckedProducts_Update, setIsCheckedProducts_Update] = useState(false);
    const [IsCheckedProducts_Delete, setIsCheckedProducts_Delete] = useState(false);

    const [IsCheckedBanks, setIsCheckedBanks] = useState(false);
    const [IsCheckedBanks_List, setIsCheckedBanks_List] = useState(false);
    const [IsCheckedBanks_Add, setIsCheckedBanks_Add] = useState(false);
    const [IsCheckedBanks_Update, setIsCheckedBanks_Update] = useState(false);
    const [IsCheckedBanks_Delete, setIsCheckedBanks_Delete] = useState(false);

    const [IsCheckedUsers, setIsCheckedUsers] = useState(false);
    const [IsCheckedUsers_List, setIsCheckedUsers_List] = useState(false);
    const [IsCheckedUsers_Add, setIsCheckedUsers_Add] = useState(false);
    const [IsCheckedUsers_Update, setIsCheckedUsers_Update] = useState(false);
    const [IsCheckedUsers_Delete, setIsCheckedUsers_Delete] = useState(false);

    const [IsCheckedRoles, setIsCheckedRoles] = useState(false);
    const [IsCheckedRoles_List, setIsCheckedRoles_List] = useState(false);
    const [IsCheckedRoles_Add, setIsCheckedRoles_Add] = useState(false);
    const [IsCheckedRoles_Update, setIsCheckedRoles_Update] = useState(false);
    const [IsCheckedRoles_Delete, setIsCheckedRoles_Delete] = useState(false);

    const handleCheckboxChangeBanners = (event) => {
        if(event.target.checked){
            setIsCheckedBanners_List(true);
            setIsCheckedBanners_Add(true);
            setIsCheckedBanners_Update(true);
            setIsCheckedBanners_Delete(true);
        }else{
            setIsCheckedBanners_List(false);
            setIsCheckedBanners_Add(false);
            setIsCheckedBanners_Update(false);
            setIsCheckedBanners_Delete(false);
        }
        setIsCheckedBanners(event.target.checked);
    };
    const handleCheckboxChangeBannersList = (event) => {
        setIsCheckedBanners_List(event.target.checked);
    };
    const handleCheckboxChangeBannersAdd = (event) => {
        setIsCheckedBanners_Add(event.target.checked);
    };
    const handleCheckboxChangeBannersUpdate = (event) => {
        setIsCheckedBanners_Update(event.target.checked);
    };
    const handleCheckboxChangeBannersDelete = (event) => {
        setIsCheckedBanners_Delete(event.target.checked);
    };

    const handleCheckboxChangeProducts = (event) => {
        if(event.target.checked){
            setIsCheckedProducts_List(true);
            setIsCheckedProducts_Add(true);
            setIsCheckedProducts_Update(true);
            setIsCheckedProducts_Delete(true);
        }else{
            setIsCheckedProducts_List(false);
            setIsCheckedProducts_Add(false);
            setIsCheckedProducts_Update(false);
            setIsCheckedProducts_Delete(false);
        }
        setIsCheckedProducts(event.target.checked);
    };
    const handleCheckboxChangeProductsList = (event) => {
        setIsCheckedProducts_List(event.target.checked);
    };
    const handleCheckboxChangeProductsAdd = (event) => {
        setIsCheckedProducts_Add(event.target.checked);
    };
    const handleCheckboxChangeProductsUpdate = (event) => {
        setIsCheckedProducts_Update(event.target.checked);
    };
    const handleCheckboxChangeProductsDelete = (event) => {
        setIsCheckedProducts_Delete(event.target.checked);
    };

    const handleCheckboxChangeUsers = (event) => {
        if(event.target.checked){
            setIsCheckedUsers_List(true);
            setIsCheckedUsers_Add(true);
            setIsCheckedUsers_Update(true);
            setIsCheckedUsers_Delete(true);
        }else{
            setIsCheckedUsers_List(false);
            setIsCheckedUsers_Add(false);
            setIsCheckedUsers_Update(false);
            setIsCheckedUsers_Delete(false);
        }
        setIsCheckedUsers(event.target.checked);
    };
    const handleCheckboxChangeUsersList = (event) => {
        setIsCheckedUsers_List(event.target.checked);
    };
    const handleCheckboxChangeUsersAdd = (event) => {
        setIsCheckedUsers_Add(event.target.checked);
    };
    const handleCheckboxChangeUsersUpdate = (event) => {
        setIsCheckedUsers_Update(event.target.checked);
    };
    const handleCheckboxChangeUsersDelete = (event) => {
        setIsCheckedUsers_Delete(event.target.checked);
    };

    const handleCheckboxChangeRoles = (event) => {
      if(event.target.checked){
          setIsCheckedRoles_List(true);
          setIsCheckedRoles_Add(true);
          setIsCheckedRoles_Update(true);
          setIsCheckedRoles_Delete(true);
      }else{
          setIsCheckedRoles_List(false);
          setIsCheckedRoles_Add(false);
          setIsCheckedRoles_Update(false);
          setIsCheckedRoles_Delete(false);
      }
      setIsCheckedRoles(event.target.checked);
  };
  const handleCheckboxChangeRolesList = (event) => {
      setIsCheckedRoles_List(event.target.checked);
  };
  const handleCheckboxChangeRolesAdd = (event) => {
      setIsCheckedRoles_Add(event.target.checked);
  };
  const handleCheckboxChangeRolesUpdate = (event) => {
      setIsCheckedRoles_Update(event.target.checked);
  };
  const handleCheckboxChangeRolesDelete = (event) => {
      setIsCheckedRoles_Delete(event.target.checked);
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const add = [];
      if (IsCheckedBanners) add.push('1');
      if (IsCheckedBanners_List) add.push('2');
      if (IsCheckedBanners_Add) add.push('3');
      if (IsCheckedBanners_Update) add.push('4');
      if (IsCheckedBanners_Delete) add.push('5');

      if (IsCheckedProducts) add.push('6');
      if (IsCheckedProducts_List) add.push('7');
      if (IsCheckedProducts_Add) add.push('8');
      if (IsCheckedProducts_Update) add.push('9');
      if (IsCheckedProducts_Delete) add.push('10');

      if (IsCheckedBanks) add.push('11');
      if (IsCheckedBanks_List) add.push('12');
      if (IsCheckedBanks_Add) add.push('13');
      if (IsCheckedBanks_Update) add.push('14');
      if (IsCheckedBanks_Delete) add.push('15');

      if (IsCheckedUsers) add.push('16');
      if (IsCheckedUsers_List) add.push('17');
      if (IsCheckedUsers_Add) add.push('18');
      if (IsCheckedUsers_Update) add.push('19');
      if (IsCheckedUsers_Delete) add.push('20');

      if (IsCheckedRoles) add.push('21');
      if (IsCheckedRoles_List) add.push('22');
      if (IsCheckedRoles_Add) add.push('23');
      if (IsCheckedRoles_Update) add.push('24');
      if (IsCheckedRoles_Delete) add.push('25');

      var param = {
        'id'         :id,
        'add'        :add,
      };
      const response = await request("role/setpermission", "post", param);
      if (response.data.status) {
        Swal.fire({
          title: "Good job!",
          text: response.data.message,
          icon: "success"
        });
        navigate("/admin/roles-permission/"+id);
      }else{
        Swal.fire({
          title: "Please Check Permission",
          text: "Please Check Permission",
          icon: "error"
        });
      }
  };
  return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Set Permission for role</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Authorize roles.</h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                      <div className="grid-2 pb-2">
                        <div className="left">
                          <h3 className="m-0 text-primary">Permission</h3>
                        </div>
                        <div className="right">
                          <Link to="/admin/roles-list">
                            <button
                              type="button"
                              className="btn bg-gradient-primary btn-sm"
                            >
                              <i className="fas fa-arrow-left nav-icon"></i>{" "}
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="row col-sm-6">

                        <li className="nav-item">
                            <div className="form-check">
                                {IsCheckedBanners ? (
                                    <i className="fas fa-caret-down font-size"></i>
                                ) : (
                                    <i className="fas fa-caret-right font-size"></i>
                                )}
                                <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedBanners} checked={IsCheckedBanners} onChange={handleCheckboxChangeBanners} />
                                <label className="form-check-label text-bold text-primary">
                                banners
                                </label>
                            </div>
                            {IsCheckedBanners &&
                                <div className="sub-form-check" id="new_list">
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedBanners_List} checked={IsCheckedBanners_List} onChange={handleCheckboxChangeBannersList} />
                                    <label className="form-check-label text-500 text-primary">
                                        banners-list
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedBanners_Add} checked={IsCheckedBanners_Add} onChange={handleCheckboxChangeBannersAdd} />
                                    <label className="form-check-label text-500 text-primary">
                                        banners-add
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedBanners_Update} checked={IsCheckedBanners_Update} onChange={handleCheckboxChangeBannersUpdate} />
                                    <label className="form-check-label text-500 text-primary">
                                        banners-update
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedBanners_Delete} checked={IsCheckedBanners_Delete} onChange={handleCheckboxChangeBannersDelete} />
                                    <label className="form-check-label text-500 text-primary">
                                        banners-delete
                                    </label>
                                    </div>
                                </div>
                            }
                        </li>

                      </div>
                      <div className="row col-sm-6">
                        <li className="nav-item">
                            <div className="form-check">
                                {IsCheckedProducts ? (
                                    <i className="fas fa-caret-down font-size"></i>
                                ) : (
                                    <i className="fas fa-caret-right font-size"></i>
                                )}
                                <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedProducts} checked={IsCheckedProducts} onChange={handleCheckboxChangeProducts} />
                                <label className="form-check-label text-bold text-primary">
                                products
                                </label>
                            </div>
                            {IsCheckedProducts &&
                                <div className="sub-form-check" id="new_list">
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedProducts_List} checked={IsCheckedProducts_List} onChange={handleCheckboxChangeProductsList} />
                                    <label className="form-check-label text-500 text-primary">
                                        products-list
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedProducts_Add} checked={IsCheckedProducts_Add} onChange={handleCheckboxChangeProductsAdd} />
                                    <label className="form-check-label text-500 text-primary">
                                        products-add
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedProducts_Update} checked={IsCheckedProducts_Update} onChange={handleCheckboxChangeProductsUpdate} />
                                    <label className="form-check-label text-500 text-primary">
                                        products-update
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedProducts_Delete} checked={IsCheckedProducts_Delete} onChange={handleCheckboxChangeProductsDelete} />
                                    <label className="form-check-label text-500 text-primary">
                                        products-delete
                                    </label>
                                    </div>
                                </div>
                            }
                          </li>
                      </div>
                      <div className="row col-sm-6">
                        <li className="nav-item">
                            <div className="form-check">
                                {IsCheckedUsers ? (
                                    <i className="fas fa-caret-down font-size"></i>
                                ) : (
                                    <i className="fas fa-caret-right font-size"></i>
                                )}
                                <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedUsers} checked={IsCheckedUsers} onChange={handleCheckboxChangeUsers} />
                                <label className="form-check-label text-bold text-primary">
                                users
                                </label>
                            </div>
                            {IsCheckedUsers &&
                                <div className="sub-form-check" id="new_list">
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedUsers_List} checked={IsCheckedUsers_List} onChange={handleCheckboxChangeUsersList} />
                                    <label className="form-check-label text-500 text-primary">
                                        users-list
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedUsers_Add} checked={IsCheckedUsers_Add} onChange={handleCheckboxChangeUsersAdd} />
                                    <label className="form-check-label text-500 text-primary">
                                        users-add
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedUsers_Update} checked={IsCheckedUsers_Update} onChange={handleCheckboxChangeUsersUpdate} />
                                    <label className="form-check-label text-500 text-primary">
                                        users-update
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedUsers_Delete} checked={IsCheckedUsers_Delete} onChange={handleCheckboxChangeUsersDelete} />
                                    <label className="form-check-label text-500 text-primary">
                                        users-delete
                                    </label>
                                    </div>
                                </div>
                            }
                          </li>
                      </div>
                      <div className="row col-sm-6">
                        <li className="nav-item">
                            <div className="form-check">
                                {IsCheckedRoles ? (
                                    <i className="fas fa-caret-down font-size"></i>
                                ) : (
                                    <i className="fas fa-caret-right font-size"></i>
                                )}
                                <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedRoles} checked={IsCheckedRoles} onChange={handleCheckboxChangeRoles} />
                                <label className="form-check-label text-bold text-primary">
                                roles
                                </label>
                            </div>
                            {IsCheckedRoles &&
                                <div className="sub-form-check" id="new_list">
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedRoles_List} checked={IsCheckedRoles_List} onChange={handleCheckboxChangeRolesList} />
                                    <label className="form-check-label text-500 text-primary">
                                        roles-list
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedRoles_Add} checked={IsCheckedRoles_Add} onChange={handleCheckboxChangeRolesAdd} />
                                    <label className="form-check-label text-500 text-primary">
                                        roles-add
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedRoles_Update} checked={IsCheckedRoles_Update} onChange={handleCheckboxChangeRolesUpdate} />
                                    <label className="form-check-label text-500 text-primary">
                                        roles-update
                                    </label>
                                    </div>
                                    <div className="form-check under">
                                    <input className="form-check-input" type="checkbox" name="add[]" defaultValue={IsCheckedRoles_Delete} checked={IsCheckedRoles_Delete} onChange={handleCheckboxChangeRolesDelete} />
                                    <label className="form-check-label text-500 text-primary">
                                        roles-delete
                                    </label>
                                    </div>
                                </div>
                            }
                          </li>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Permission;
