import { Link ,useNavigate,useParams  } from "react-router-dom";
import React, { useState ,useEffect,useCallback } from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Update = () => {
    const { id } = useParams();
    const [ImgLogo, setImgLogo] = useState('');
    const [ImgQr, setImgQr] = useState('');
    const [status, setstatus] = useState('');
    const navigate = useNavigate();
    const getBank = useCallback(async () => {
        const response = await request(`bank/${id}`, "get");
        if (response.data.status) {
            const bank = response.data.data[0]; // Adjust according to the response structure
            setImgLogo(bank.img_logo_url || '');
            setImgQr(bank.img_qr_url || '');
            setstatus(bank.status || '');
        }
    }, [id]);
    useEffect(() => {
        getBank();
    }, [getBank]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        var param = {
        'id'         :id,
        'img_logo_url'    :ImgLogo,
        'img_qr_url'      :ImgQr,
        'status'     :status,
        };
        const response = await request("bank/update", "put", param);
        if (response.data.message) {
        Swal.fire({
            title: "Good job!",
            text: response.data.message,
            icon: "success"
        });
        navigate("/admin/banks-list");
        }else if (response.data.error) {
          if (response.data.error.img_logo_url) {
              Swal.fire({
              title: "Please Check Information Bank",
              text: response.data.error.img_logo_url,
              icon: "error"
              });
          }
        }
    };
    return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Bank</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      Please fill in the information below. The field labels
                      marked with * are required input fields.
                    </h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                        <div className="grid-2 pb-2">
                          <div className="left">
                          <h3 className="m-0 text-primary">Information</h3>
                          </div>
                          <div className="right">
                            <Link to="/admin/banks-list">
                              <button
                                type="button"
                                className="btn bg-gradient-primary btn-sm"
                              >
                                <i className="fas fa-arrow-left nav-icon"></i>{" "}
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="row">

                          <div className="col-sm-9">
                            <div className="form-group">
                              <label> Img Logo Link <span className="text-danger text-unit">*</span> </label>
                              <input type="text" className="form-control" placeholder="Enter Img Logo Link"  value={ImgLogo} onChange={(e) => setImgLogo(e.target.value)} required />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                            <center><label> Img Logo Link Preview</label></center>
                              <center><img src={ImgLogo} style={{ width: "35px" }} alt={ImgQr} /></center>
                            </div>
                          </div>

                          <div className="col-sm-9">
                            <div className="form-group">
                              <label> Img Qr Link</label>
                              <input type="text" className="form-control" placeholder="Enter Img Qr Link"  value={ImgQr} onChange={(e) => setImgQr(e.target.value)} />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                            <center><label> Img Qr Link Preview</label></center>
                              <center><img src={ImgQr} style={{ width: "35px" }} alt={ImgQr} /></center>
                            </div>
                          </div>

                        </div>
                      
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Update;
