import { Link ,useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Add = () => {
  const [nameKH, setnameKH] = useState('');
  const [nameEN, setnameEN] = useState('');
  const [detailKH, setdetailKH] = useState('');
  const [detailEN, setdetailEN] = useState('');
  const [imgLinkKH, setimgLinkKH] = useState('');
  const [imgLinkEN, setimgLinkEN] = useState('');
  const [redirect, setredirect] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    var param = {
      'name_kh'    :nameKH,
      'name_en'    :nameEN,
      'detail_kh'  :detailKH,
      'detail_en'  :detailEN,
      'img_url_kh' :imgLinkKH,
      'img_url_en' :imgLinkEN,
      'redirect'   :redirect,
    };
    const response = await request("product/create", "post", param);
    if (response.data.message) {
      Swal.fire({
        title: "Good job!",
        text: response.data.message,
        icon: "success"
      });
      navigate("/admin/products-list");
    }else if (response.data.error) {
      if (response.data.error.Name_kh) {
        Swal.fire({
          title: "Please Check Information Product",
          text: response.data.error.Name_kh,
          icon: "error"
        });
      }
      if (response.data.error.Name_en) {
        Swal.fire({
          title: "Please Check Information Product",
          text: response.data.error.Name_en,
          icon: "error"
        });
      }
    
    }
  };
  return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add Product</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      Please fill in the information below. The field labels
                      marked with * are required input fields.
                    </h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                        <div className="grid-2 pb-2">
                          <div className="left">
                          <h3 className="m-0 text-primary">Information</h3>
                          </div>
                          <div className="right">
                            <Link to="/admin/products-list">
                              <button
                                type="button"
                                className="btn bg-gradient-primary btn-sm"
                              >
                                <i className="fas fa-arrow-left nav-icon"></i>{" "}
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="row">

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Name (KH) <span className="text-danger text-unit">*</span> </label>
                                <input type="text" className="form-control" placeholder="Enter Name (KH)"  value={nameKH} onChange={(e) => setnameKH(e.target.value)} required />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                  <label> Name (EN) <span className="text-danger text-unit">*</span></label>
                                  <input type="text" className="form-control" placeholder="Enter Name (EN)" value={nameEN} onChange={(e) => setnameEN(e.target.value)} required/>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Detail (KH)</label>
                                <textarea type="text" className="form-control" placeholder="Enter Detail (KH)" value={detailKH} onChange={(e) => setdetailKH(e.target.value)}> </textarea>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                  <label> Detail (EN)</label>
                                  <textarea type="text" className="form-control" placeholder="Enter Detail (EN)" value={detailEN} onChange={(e) => setdetailEN(e.target.value)}> </textarea>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Img Link (KH) <span className="text-danger text-unit">*</span></label>
                                <input type="text" className="form-control" placeholder="Enter Img Link (KH)" value={imgLinkKH} onChange={(e) => setimgLinkKH(e.target.value)} required />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                  <label> Img Link (EN) <span className="text-danger text-unit">*</span></label>
                                  <input type="text" className="form-control" placeholder="Enter Img Link (EN)" value={imgLinkEN} onChange={(e) => setimgLinkEN(e.target.value)} required/>
                              </div>
                            </div>
                            
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label> Redirect</label>
                                <input type="text" className="form-control" placeholder="Enter Redirect" value={redirect} onChange={(e) => setredirect(e.target.value)}/>
                              </div>
                            </div>

                        </div>
                      
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add;
