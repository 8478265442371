import { BrowserRouter, Routes, Route} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Application from "./components/layouts/App";
import Dashboard from "./components/view/Dashboard";
import Login from "./components/view/Login";
import Notfound from "./components/view/Notfound";
import Home from "./components/view/admin/Home";
import Product from "./components/view/admin/products/List";
import ProductAdd from "./components/view/admin/products/Add";
import ProductUpdate from "./components/view/admin/products/Update";
import Bank from "./components/view/admin/banks/List";
import BankAdd from "./components/view/admin/banks/Add";
import BankUpdate from "./components/view/admin/banks/Update";
import Banner from "./components/view/admin/banners/List";
import BannerAdd from "./components/view/admin/banners/Add";
import BannerUpdate from "./components/view/admin/banners/Update";
import User from "./components/view/admin/users/List";
import UserAdd from "./components/view/admin/users/Add";
import UserUpdate from "./components/view/admin/users/Update";

import Role from "./components/view/admin/roles/List";
import RoleAdd from "./components/view/admin/roles/Add";
import RoleUpdate from "./components/view/admin/roles/Update";
import Permission from "./components/view/admin/roles/Permission";
function App() {
  return (
    <BrowserRouter basename="/">
    <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Notfound />} />
        
        <Route element={<Application />} path="admin">
          <Route path="dashboard" element={<Home />} />
          <Route path="banners-list" element={<Banner />} />
          <Route path="banners-add" element={<BannerAdd />} />
          <Route path="banners-update/:id" element={<BannerUpdate />} />
          <Route path="products-list" element={<Product />} />
          <Route path="products-add" element={<ProductAdd />} />
          <Route path="products-update/:id" element={<ProductUpdate />} />
          <Route path="banks-list" element={<Bank />} />
          <Route path="banks-add" element={<BankAdd />} />
          <Route path="banks-update/:id" element={<BankUpdate />} />
          <Route path="users-list" element={<User />} />
          <Route path="users-add" element={<UserAdd />} />
          <Route path="users-update/:id" element={<UserUpdate />} />
          <Route path="roles-list" element={<Role />} />
          <Route path="roles-add" element={<RoleAdd />} />
          <Route path="roles-update/:id" element={<RoleUpdate />} />
          <Route path="roles-permission/:id" element={<Permission />} />
        </Route>
    </Routes>
    <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
  </BrowserRouter>
  );
}
export default App;