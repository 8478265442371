import { Link ,useNavigate,useParams  } from "react-router-dom";
import React, { useState ,useEffect,useCallback } from "react";
import { request } from "../../../../util/request";
import Swal from "sweetalert2";
const Update = () => {
    const { id } = useParams();
    const [name, setname] = useState('');
    const navigate = useNavigate();
    const getRole = useCallback(async () => {
        const response = await request(`role/${id}`, "get");
        if (response.data.status) {
            const role = response.data.data[0]; // Adjust according to the response structure
            setname(role.name || '');
        }
    }, [id]);
    useEffect(() => {
        getRole();
    }, [getRole]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        var param = {
        'id'         :id,
        'name'       :name,
        };
        const response = await request("role/update", "put", param);
        if (response.data.message) {
        Swal.fire({
            title: "Good job!",
            text: response.data.message,
            icon: "success"
        });
        navigate("/admin/roles-list");
        }else if (response.data.error) {
          if (response.data.error.Name) {
              Swal.fire({
              title: "Please Check Information Role",
              text: response.data.error.Name,
              icon: "error"
              });
          }
        }
    };
    return (
    <div className="content-wrapper iframe-mode">
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Role</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">
                      Please fill in the information below. The field labels
                      marked with * are required input fields.
                    </h5>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="card-body table-responsive">
                        <div className="grid-2 pb-2">
                          <div className="left">
                          <h3 className="m-0 text-primary">Information</h3>
                          </div>
                          <div className="right">
                            <Link to="/admin/roles-list">
                              <button
                                type="button"
                                className="btn bg-gradient-primary btn-sm"
                              >
                                <i className="fas fa-arrow-left nav-icon"></i>{" "}
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="row col-sm-12">

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> Name <span className="text-danger text-unit">*</span> </label>
                                <input type="text" className="form-control" placeholder="Enter Name"  value={name} onChange={(e) => setname(e.target.value)} required />
                              </div>
                            </div>
                        </div>
                      
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Update;
